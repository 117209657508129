import '@ephatta/components/dist/index.css'
import { MutationLogState, Root } from '@ephatta/components'
import Routes from './pages/Routes'
import { Theme } from './config/Theme'
import 'react-phone-number-input/style.css'
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const App = () => {

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DndProvider backend={HTML5Backend}>
                <Root
                    theme={Theme}
                    loginPath="/login"
                    initialPath="/"
                    routes={Routes}
                    loggedRoutes={loggedRoutes}
                    loggedOutRoutes={loggedOutRoutes}
                    loggedUnAuthorizedRootes={loggedUnAuthorizedRootes}
                    initialState={[
                        {
                            state: MutationLogState,
                            value: true
                        }
                    ]}
                />
            </DndProvider>
        </LocalizationProvider>
    )
}

const loggedRoutes = '*'

const loggedOutRoutes = [
    '/login',
    '/forgot-password',
    '/establishment/proximity',
    '/register'
]

const loggedUnAuthorizedRootes = [
    '/login',
    '/forgot-password'
]

export default App;
