var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpService, Logger } from "../../utils";
import { BaseService } from "../BaseService";
var MangoPayService = /** @class */ (function (_super) {
    __extends(MangoPayService, _super);
    function MangoPayService(params) {
        var _this = _super.call(this, params) || this;
        _this.init();
        return _this;
    }
    MangoPayService.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tokenExpirationDate;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Retrieve the access token and expiration date from sessionStorage
                        this.accessToken = sessionStorage.getItem('accessToken');
                        tokenExpirationDate = sessionStorage.getItem('tokenExpirationDate');
                        if (tokenExpirationDate) {
                            this.tokenExpirationDate = new Date(tokenExpirationDate);
                        }
                        if (!(!this.accessToken || this.isTokenExpired())) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getOAuthToken()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.getOAuthToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, expiresIn;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, HttpService.get("".concat(process.env.REACT_APP_API_URL, "/admin/log/mangopay/token"), {}, {})];
                    case 1:
                        response = _a.sent();
                        this.accessToken = response.data.access_token;
                        expiresIn = response.data.expires_in;
                        this.tokenExpirationDate = new Date(Date.now() + expiresIn * 1000);
                        // Store the access token and expiration date in sessionStorage
                        sessionStorage.setItem('accessToken', this.accessToken);
                        sessionStorage.setItem('tokenExpirationDate', this.tokenExpirationDate.toISOString());
                        return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.isTokenExpired = function () {
        if (!this.tokenExpirationDate) {
            return true;
        }
        return this.tokenExpirationDate <= new Date();
    };
    // Verify token is expired or not and if expired then get new token
    MangoPayService.prototype.ensureAccessToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.accessToken || this.isTokenExpired())) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getOAuthToken()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.getOAuthHeaders = function () {
        return [
            { key: 'Authorization', value: "Bearer ".concat(this.accessToken) }
        ];
    };
    MangoPayService.prototype.getCardsByUserId = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.get("".concat(this.controllerUrl, "/mangopay/cards/GetCardsByUserId/").concat(userId), {}, {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_1 = _a.sent();
                        Logger.error(err_1, { method: this.getCardsByUserId.name });
                        throw err_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.getUserByEmail = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.get("".concat(this.controllerUrl, "/mangopay/users/getUserByEmail/").concat(email), {}, {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_2 = _a.sent();
                        Logger.error(err_2, { method: this.getCardsByUserId.name });
                        throw err_2;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.createCardRegistration = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.post("".concat(this.controllerUrl, "/mangopay/cards/createCardResgistration"), payload, {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_3 = _a.sent();
                        Logger.error(err_3, { method: this.createCardRegistration.name });
                        throw err_3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.createUserNatural = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.post("".concat(this.controllerUrl, "/mangopay/users/createNaturalUser"), payload, {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_4 = _a.sent();
                        Logger.error(err_4, { method: this.createUserNatural.name });
                        throw err_4;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.createUserWallet = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.ensureAccessToken()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, HttpService.post("".concat(this.controllerUrl, "/mangopay/wallets/createWallet"), payload, { headers: this.getOAuthHeaders() })];
                    case 2:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 3:
                        err_5 = _a.sent();
                        Logger.error(err_5, { method: this.createUserWallet.name });
                        throw err_5;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.createUserRegisterBankAccount = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.ensureAccessToken()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, HttpService.post("".concat(this.controllerUrl, "/mangopay/banks/CreateBank"), payload, { headers: this.getOAuthHeaders() })];
                    case 2:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 3:
                        err_6 = _a.sent();
                        Logger.error(err_6, { method: this.createUserRegisterBankAccount.name });
                        throw err_6;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.createUserSendCardDetails = function (url, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var urlSearchParams_1, result, err_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        urlSearchParams_1 = new URLSearchParams();
                        Object.keys(payload).forEach(function (key) {
                            urlSearchParams_1.append(key, payload[key]);
                        });
                        return [4 /*yield*/, HttpService.post(url, urlSearchParams_1, {
                                headers: [
                                    { key: 'Content-Type', value: 'application/x-www-form-urlencoded' },
                                    { key: 'Authorization', value: '' }
                                ]
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.text()];
                    case 2:
                        err_7 = _a.sent();
                        Logger.error(err_7, { method: this.createUserSendCardDetails.name });
                        throw err_7;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.cardRegistration = function (CardRegistrationId, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.put("".concat(this.controllerUrl, "/mangopay/cards/updateCardResgistration/").concat(CardRegistrationId), payload, {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_8 = _a.sent();
                        Logger.error(err_8, { method: this.cardRegistration.name });
                        throw err_8;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.deactivateCard = function (cardId, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.delete("".concat(this.controllerUrl, "/customer/paymentMethod/").concat(cardId), payload, {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_9 = _a.sent();
                        Logger.error(err_9, { method: this.cardRegistration.name });
                        throw err_9;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.createCardDirectPayIn = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.post("".concat(this.controllerUrl, "/mangopay/payins/CreateCardDirectPayIn"), payload, {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_10 = _a.sent();
                        Logger.error(err_10, { method: this.createCardDirectPayIn.name });
                        throw err_10;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.details = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_11;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.get("".concat(this.controllerUrl, "/customer/mangopayAccount"), {
                                userId: userId,
                            }, {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_11 = _a.sent();
                        Logger.error(err_11, { method: this.details.name });
                        throw err_11;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MangoPayService.prototype.payout = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_12;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.post("".concat(this.controllerUrl, "/customer/mangopayAccount/payout"), payload, {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_12 = _a.sent();
                        Logger.error(err_12, { method: this.payout.name });
                        throw err_12;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return MangoPayService;
}(BaseService));
export { MangoPayService };
