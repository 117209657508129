import { ButtonSubmit, InputLabel, useFormData, useTranslations, useEventInsert, useRuntimeEnv } from "@ephatta/components"
import { TClientIdentity } from "@ephatta/services"
import { Grid, Link, MenuItem, Select, Typography } from "@mui/material"
import { FormEvent,  useState } from "react"
import { useExportTypes } from "./hooks/useExportTypes"

export const Container = () => {

    const [
        TITLE,
        BUTTON,
    ] = useTranslations(i18n)
    const types = useExportTypes()

    const mutation = useEventInsert()

    const env = useRuntimeEnv<TClientIdentity>()
    const { formData, handleInputChange, isValid, getTextFieldProps } = useFormData<{ type: string }>({
        formData: {
            type: ''
        },
        required: ['type']
    })
    
    const handleLogExport = () => {
        mutation.mutate({
            message: `Un journal de type ${formData.type} a été exporté sur la page <a href="/export">Export</a>`,
            userId: env?.userId,
        })
    }

    return (
        <>
            <Typography variant="h5" className="mb-30">{TITLE}</Typography>
            <form >
                <Grid container>
                    <Grid xs={12} sm={6} lg={4}>
                        <Grid container>
                            <Grid xs={12}>
                                <Grid container>
                                    <Grid xs={2}>
                                        <InputLabel label="Type :" />
                                    </Grid>
                                    
                                    <Grid xs={10} style={{ paddingRight: 30 }}>
                                        <Select
                                            {...getTextFieldProps('type')}
                                            onChange={(e) => handleInputChange('type', e.target.value)}
                                            style={{ width: '100%' }}>
                                            {
                                                types.map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{ item.label }</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* <Grid xs={12} className="mt-30">
                                <Grid container>
                                    <Grid sm={6}>
                                        <DatePicker
                                            label="Du :"
                                            variant="inline"
                                            disableToolbar
                                            format="dd/MM/yyyy"
                                            value={formData.dateBegin}
                                            onChange={(date) => handleInputChange('dateBegin', date?.format('YYYY-MM-DD'))}
                                            />
                                    </Grid>
                                    <Grid sm={6}>
                                        <DatePicker
                                            label="au :"
                                            variant="inline"
                                            disableToolbar
                                            format="dd/MM/yyyy"
                                            value={formData.dateEnd}
                                            onChange={(date) => handleInputChange('dateEnd', date?.format('YYYY-MM-DD'))}
                                            />
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            
                            <Grid xs={12}>
                                <Link href={`${process.env.REACT_APP_API_URL}/admin/statistic/Export/${formData.type}`}>
                                    <ButtonSubmit 
                                        color="primary" 
                                        variant="contained" 
                                        className="rounded mt-30 float-right"
                                        // type="submit"
                                        onClick={handleLogExport}
                                        disabled={!Boolean(formData.type)}>
                                        {BUTTON}
                                    </ButtonSubmit>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            
        </>
    )
}

const i18n = [
    'MiddleOffice.Export.Title',
    'MiddleOffice.Export.Button.label',
]



function getContentDispositionFilenameFromARequest(request: Request) {
    const contentDisposition = request.headers.get('content-disposition')
    if (contentDisposition) {
        const filename = contentDisposition.split('filename=')[1]
        if (filename) {
            return filename
        }
    }
    return null
}